import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Input, Table } from 'reactstrap';
import { Translate, TextFormat, getPaginationState, JhiPagination, JhiItemCount, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown, faUser } from '@fortawesome/free-solid-svg-icons';
import { APP_LOCAL_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities, getClinicPatients } from './patient.reducer';
import RawImageViewer from './consultation/image/raw-image-viewer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import authentication from 'app/shared/reducers/authentication';
import { isMobile, isMobileOnly } from 'react-device-detect';

export interface IPatientProps {
  readOnly?: boolean
}

export const Patient = ({ readOnly = false } : IPatientProps) => {
  const dispatch = useAppDispatch();

  const { clinicId } = useParams<'clinicId'>();
  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const authorities = useAppSelector(state => state.authentication.account?.authorities);

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );

  const clearImageCache = () => {
    sessionStorage.clear(); // Clear session cache to avoid images loaded from consultations to create memory leak
  };

  const patientList = useAppSelector(state => state.patient.entities);
  const loading = useAppSelector(state => state.patient.loading);
  const totalItems = useAppSelector(state => state.patient.totalItems)

  const getAllEntities = () => {
    const props = {
      page: paginationState.activePage - 1,
      size: paginationState.itemsPerPage,
      sort: `${paginationState.sort},${paginationState.order}`,
      search: debouncedSearchTerm
    }

    if(clinicId === undefined) {
      dispatch(
        getEntities(props),
      );
    } else {
      dispatch(getClinicPatients({
        id: clinicId,
        ...props
      }));
    }
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  const navigateToPatientDetails = (patientId) => {
    if(hasAnyAuthority(authorities, [AUTHORITIES.VIEW_PATIENT_DETAILS])) {
      navigate(`/patient/${patientId}`);
    }
  }

  useEffect(() => {
    clearImageCache();
  }, []);

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, debouncedSearchTerm]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = paginationState.sort;
    const order = paginationState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  return (
    <div>
      <h2 id="patient-heading" data-cy="PatientHeading">
        <Translate contentKey="dfaiApp.patient.home.title">Patients</Translate>
      </h2>
      <div className={`d-flex ${isMobile ? "flex-wrap" : ""} justify-content-between`}>
        <div className="input-group mb-3 w-100">
            <Input
                type="text"
                className="form-control"
                placeholder={translate('dfaiApp.patient.search.placeholder')}
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
            />
        </div>
        { !readOnly && hasAnyAuthority(authorities, [AUTHORITIES.CREATE_PATIENT]) && 
          <div>
            <Link to="/patient/new" className="btn btn-primary jh-create-entity mx-2 text-nowrap" id="jh-create-entity" data-cy="entityCreateButton">
              <FontAwesomeIcon icon="plus" />
              &nbsp;
              <span className="text-nowrap">{translate("dfaiApp.patient.home.createLabel")}</span>
            </Link>
          </div>
        }
      </div>
      <div className="table-responsive">
        {patientList && patientList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th/>
                <th className="hand" onClick={sort('firstName')}>
                  <Translate contentKey="dfaiApp.patient.firstName">First Name</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('firstName')} />
                </th>
                <th className="hand" onClick={sort('lastName')}>
                  <Translate contentKey="dfaiApp.patient.lastName">Last Name</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('lastName')} />
                </th>
                {!isMobile && <th className="hand" onClick={sort('birthdate')}>
                  <Translate contentKey="dfaiApp.patient.birthdate">Birthdate</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('birthdate')} />
                </th>}
                {!isMobile && <th className="hand" onClick={sort('sex')}>
                  <Translate contentKey="dfaiApp.patient.sex">Sex</Translate> <FontAwesomeIcon icon={getSortIconByFieldName('sex')} />
                </th>}
                {!isMobile && <th className="hand" onClick={sort('socialNumber')}>
                  <Translate contentKey="dfaiApp.patient.socialNumber">Social Number</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('socialNumber')} />
                </th>}
                { !readOnly && !isMobileOnly && <th/> }
              </tr>
            </thead>
            <tbody>
              {patientList.map((patient, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable" className="clickable-row">
                  <td onClick={() => navigate(`/patient/${patient.id}`)} className="align-middle text-end py-0">
                  <div className="px-2 pr-6 py-2 patient-photo-container">
                      {patient.photoFileName ?
                        <RawImageViewer image={{id: patient.photoFileName, storedName: patient.photoFileName}} imagePatientId={patient.id} source="patient" rounded={true}/>
                        :
                        <FontAwesomeIcon 
                          icon={faUser} 
                          className="border rounded-circle text-muted overflow-hidden" 
                          style={{ height: '3rem', width: '3rem', strokeWidth: '0.5px' }} 
                        />}
                    </div>
                  </td>
                  <td onClick={() => navigateToPatientDetails(patient.id)} className="align-middle py-0">{patient.firstName}</td>
                  <td onClick={() => navigateToPatientDetails(patient.id)} className="align-middle py-0">{patient.lastName?.toUpperCase()}</td>
                  {!isMobile && <td onClick={() => navigateToPatientDetails(patient.id)} className="align-middle py-0">{patient.birthdate ? <TextFormat type="date" value={patient.birthdate} format={APP_LOCAL_DATE_FORMAT} /> : null}</td>}
                  {!isMobile && <td onClick={() => navigateToPatientDetails(patient.id)} className="align-middle py-0">
                    {translate(`dfaiApp.Sex.${patient.sex}`)}
                  </td>}
                  {!isMobile && <td onClick={() => navigateToPatientDetails(patient.id)} className="align-middle  py-0 text-start">{patient.socialNumber}</td>}
                  { !readOnly && !isMobileOnly && hasAnyAuthority(authorities, [AUTHORITIES.VIEW_PATIENT_DETAILS, AUTHORITIES.UPDATE_PATIENT]) &&
                    <td className="align-middle">
                      <div className="d-flex">
                        { hasAnyAuthority(authorities, [AUTHORITIES.VIEW_PATIENT_DETAILS]) &&
                        <Button tag={Link} to={`/patient/${patient.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                          <FontAwesomeIcon icon="eye" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.view">View</Translate>
                          </span>
                        </Button>}
                          { hasAnyAuthority(authorities, [AUTHORITIES.UPDATE_PATIENT]) &&
                            <Button
                              tag={Link}
                              to={`/patient/${patient.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                              color="info"
                              size="sm"
                              data-cy="entityEditButton"
                            >
                              <FontAwesomeIcon icon="pencil-alt" />{' '}
                              <span className="d-none d-md-inline">
                                <Translate contentKey="entity.action.edit">Edit</Translate>
                              </span>
                            </Button>}
                          { hasAnyAuthority(authorities, [AUTHORITIES.DELETE_PATIENT]) &&
                            <Button
                              onClick={() =>
                                (window.location.href = `/patient/${patient.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`)
                              }
                              color="warning"
                              size="sm"
                              data-cy="entityDeleteButton"
                            >
                              <FontAwesomeIcon icon="archive" />{' '}
                              <span className="d-none d-md-inline">
                                <Translate contentKey="entity.action.archive">Archive</Translate>
                              </span>
                            </Button>}
                      </div>
                    </td>
                    }
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="dfaiApp.patient.home.notFound">No Patients found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={patientList && patientList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Patient;
