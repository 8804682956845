import React, { useEffect, useState } from 'react';
import { useAppDispatch } from 'app/config/store';
import { getConsultationImage } from '../consultation.reducer';
import { useParams } from 'react-router';
import { getPatientPhoto } from '../../../patient/patient.reducer';

// Fonction utilitaire pour accéder au cache (sessionStorage)
const getImageFromCache = (key) => {
  const cachedImage = sessionStorage.getItem(key);
  return cachedImage ? cachedImage : null;
};

// Fonction utilitaire pour ajouter une image au cache (sessionStorage)
const setImageInCache = (key, url) => {
  sessionStorage.setItem(key, url);
};

const ImageViewer = ({
  image,
  source = "consultation",
  imagePatientId = null,
  rounded = false,
  roundedCorner = false,
  noContainer = false
}) => {

  const dispatch = useAppDispatch();
  const { patientId } = useParams();
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    const fetchImage = async () => {
      if (image.storedName) {
        const cacheKey = `${source}-${image.id || imagePatientId}`;
        
        // Vérifie si l'image est dans le cache
        const cachedUrl = getImageFromCache(cacheKey);
        if (cachedUrl) {
          setImageUrl(cachedUrl);
          return;
        }

        // Sinon, récupérer l'image et la mettre en cache
        try {
          let response;
          if (source === "consultation") {
            response = await dispatch(getConsultationImage({ patientId, image: image })).unwrap();
          } else if (source === "patient") {
            response = await dispatch(getPatientPhoto(imagePatientId)).unwrap();
          }
          
          const objectURL = URL.createObjectURL(response);
          setImageUrl(objectURL);

          // Stocker l'URL dans le cache persistant
          setImageInCache(cacheKey, objectURL);

          // Nettoyage de l'URL pour éviter les fuites mémoire
          return () => URL.revokeObjectURL(objectURL);
        } catch (error) {
          console.error('Failed to load image:', error);
        }
      }
    };

    fetchImage();
  }, [dispatch, image, patientId, source, imagePatientId]);

  return (
    <React.Fragment>
      {noContainer && image ?
        <img
          id={`image-${image.id}`}
          src={imageUrl}
          draggable={false}
          className={`img-fluid h-100 ${rounded ? "rounded-circle" : roundedCorner ? "rounded" : ""}`}
          style={rounded ? { height: '5rem', width: '5rem', strokeWidth: '0.5px' } : {}}
        />
        :
        <div className="h-100">
          {image && 
            <img
              id={`image-${image.id}`}
              src={imageUrl}
              draggable={false}
              className={`img-fluid h-100 ${rounded ? "rounded-circle" : roundedCorner ? "rounded" : ""}`}
              style={rounded ? { height: '5rem', width: '5rem', strokeWidth: '0.5px' } : {}}
            />
          }
        </div>}
    </React.Fragment>
  );
};

export default ImageViewer;
